.CustomerCards{
    /* display: flex; */
    gap: 5px;
    width: 100%;
}

.wrapper{
    gap: 1rem;
    justify-content: left;
}

#options{
    color: gray;
    font-size: 12px;
}

/* Circular Progress Customer Bar */

.circularBar{
    padding-top: 0.5rem;
    width: 50%!important;
    height: auto!important;
    /* border-radius: 50rem; */
    /* background-color: rgb(246, 243, 248); */
    stroke: rgb(119,75,235)!important;
    /* font-size: 8px; */
}
.CircularProgressbar{
    overflow: visible;
}

.CircularProgressbar-path{
    stroke: rgb(119,75,235)!important;
    stroke-width: 14px !important;
    filter: drop-shadow(2px 4px 6px #d981af);
}
.CircularProgressbar-trail{
    display: none;
}
.CircularProgressbar-text{
    fill : rgb(119,75,235)!important
}

#merID:hover {
    color: #635BFF;
  }