.App {
  text-align: center;
}

@media screen and (width <= 600px) {
  .send-e-container{
    padding: 2rem !important;
  }
  .send-e-form{
    padding: 3rem !important;
  }
  .send-e-button{
    font-size: 16px !important;
  }
}

@media screen and (width <= 400px) {
  .send-e-form{
    padding: 2rem !important;
  }
  .send-e-button{
    font-size: 12px !important;
  }
}


