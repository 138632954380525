.documentation-container {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .documentation-container h1,
  .documentation-container h2,
  .documentation-container h3,
  .documentation-container h4 {
    color: #343a40;
  }
  
  .documentation-container p {
    color: #495057;
    line-height: 1.6;
  }
  
  .documentation-container ol {
    padding-left: 20px;
  }
  
  .documentation-container li {
    margin-bottom: 10px;
  }
  
  .note {
    font-weight: bolder;
    font-size: large;
  }