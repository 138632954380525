body.dark {
	--light: #0C0C1E;
	--grey: #060714;
	--dark: #FBFBFB;
    --white: #FFFFFF;
}

#Sidebar{
    display: flex;
    flex-direction: column;
    position: relative;
    /* padding-top: 4rem; */
    transition: all 300ms ease;
    background: var(--white);
    overflow-x: hidden;
	scrollbar-width: none;
    margin-top: 3rem;
}

.logo{
    display: flex;
    height: 5rem;
    font-weight: bold;
    font-size: 18px;
    gap: 1rem;
    margin-left: 1.5rem;
    margin-top: 1.5rem;
    /* align-items: center;
    justify-content: center; */
}
.logo>img{
    width: 3rem;
    height: 3rem;
    
}

#arrowBtn{
    border: none;
    background: none;
}

.menu{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-left: 2rem;
}

.menuItem{
    display: flex;
    align-items: center;
    gap: 2rem;
    color: gray;
    /* height: 2.5rem; */
    position: relative;
    transition: all 300ms ease;
    /* border-radius: 0.2rem; */
    padding-left: 10px;
    /* font-weight: bold; */
}
.menuItem:hover{
    background-color: #F2EFFF;
    padding: 0.4rem;
    border-radius: 0.2rem;
    color: rgb(128,133,227);
    /* color: rgb(62,0,194); */
    transition: .5s ease;
}
.menuItem.active{
    background-color: rgb(128,133,227);
    padding: 0.4rem;
    /* margin-left: 0.3rem; */
    /* width: 60%; */
    /* width: 160px;
    height: 40px; */
    border-radius: 0.2rem;
    color: white;
    transition: .5s ease;
}
/* .menuIcon{
    width: 100px!important;
} */
/* .close{
    display: none;
} */
.logoutmenu{
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    /* margin-bottom: 2rem; */
}
.logoutItem{
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: 30rem;
    color: gray;
    position: relative;
    transition: all 300ms ease;
    /* border-radius: 0.2rem; */
}
.Link {
    text-decoration: none;
}

.NavBrand{
    margin-left: 80rem;
    justify-content: flex-end;
    align-items: center;
    display: flex;
}


@media screen and (width <= 600px) {
    .NavBrand{
        margin-left: 40rem;
    }
}

@media screen and (width <= 200px) {
    .NavBrand{
        margin-left: 20rem;
    }
}



