#tableCard{
    border: none;
    background-color: white;
    margin-top: 2rem;
    border-radius: 30px;
    width: 85%;
}

#tableCardBody{
    margin-bottom: 2rem;
    margin-top: 2rem;
    justify-content: left;

}

#tableCardBody2{
    display: flex;
    justify-content:space-between;
    margin-bottom: 2rem;
}

#tableTitle{
    margin-bottom: 25px;
    /* margin-left: -10rem; */
}
.search {
    width: 10%;
}
.filter{
    width: 9%;
}


.noData{
    height: 25ch;
    font-weight: bold;
    margin: auto;
    text-align: center;
    vertical-align: middle
}

#pageN{
    color: rgb(98,77,227);
    border: none;
    background-color: lightgray;
    margin-left: 5px;
    border-radius: 5px;
}
.activePage {
    font-weight: bolder;
}

#previous{
    color: rgb(98,77,227);
    border: none;
}

#next{
    color: rgb(98,77,227);
    border: none;
    margin-left: 2px;
}

/* Search & Reset Buttons */
.searchButton{
    color: rgb(98,77,227);
    background-color: none;
    height: 38px;
    margin-top: -0.9rem;
    margin-inline: 0.5rem;
}
.searchButton:hover::after {
    content: 'search';
    position: absolute;
    top: 15%;
    /* left: 50%; */
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 4px;
  }

.searchButton.charges:hover::after {
    top: 23%;
}

.resetButton{
    color: rgb(98,77,227);
    background-color: none;
    height: 38px;
    margin-top: -0.9rem;
}
.resetButton:hover::after {
    content: 'reset';
    position: absolute;
    top: 15%;
    /* left: 50%; */
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 4px;
  }

.resetButton.charges:hover::after {
    top: 23%;
}

/* .chargesButton:hover::after{
    content: 'show Charges';
    position: absolute;
    top: 39%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 4px;
} */

.filter1{
    width: 7%;
}
.filter1:hover::after{
    content: 'No.Rows / page';
    position: absolute;
    top: 23.5%;
    /* left: 50%; */
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 4px;
}

.table-row-hover:hover{
    outline: 1px solid #8085e3;
}