html{
  background-color: rgb(250,251,255);
  width: 99%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mDetail {
  position: relative;
  display: inline-block;
  padding-right: 1rem;
}

.mDetail .mDetailHover{
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: white;
  text-align: center;
  border-radius: 5px;
  padding: 5px 2px;
  position: absolute;
  z-index: 1;
  bottom: 115%;
  left: 50%;
  margin-left: -4rem;
  font-size: 12px;
}
.mDetail .mDetailHover::after{
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -.5em;
  border-width: 5px;
  border-style: solid;
  border-color:#333 transparent transparent transparent ;
}

.mDetail:hover .mDetailHover{
  visibility: visible;
}


#mDetail:hover::after {
  content: 'view merchants details';
  position:relative;
  top: 100%;
  left: 50%;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 4px;
}

