.admin-table-container {
  /* background-color: #fff; */
  padding-top: 1rem;
  width: 99%;
}
.admin-table-container .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin-table-container table {
  background-color: #ffffff;
  width: 100%;
}
.admin-table-container table tr.clickable-tr:hover {
  outline: 1px solid #8085e3;
  /* cursor: pointer; */
}
.admin-table-container table tr td{
  padding: 1rem 0.2rem;
}
.admin-table-container table thead tr {
  background-color: #fff;
}
.admin-table-container table tbody tr:nth-of-type(odd) {
  background-color: #f7f6fe;
}
.admin-table-container .admin-table-pagination button {
  padding: 0.2rem 0.5rem;
  border-color: #8085e3;
  background-color: #8085e3;
  margin-top: 0.5rem;
}
.admin-table-container .admin-table-pagination button:first-of-type {
  margin-right: 0.5em;
}