#activeTab {
    font-weight: bolder;
    background-color: white;
    color: #635BFF;
    border-color: #635BFF;
    margin-right: 0.3rem;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
  }

#Tab {
  color: gray;
  background-color: white;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  margin-right: 0.3rem;
  border-color: lightgrey;
}