.create-form {
  width: clamp(150px, 700px, 100%);
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.create-form .price-field {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}
.create-form .price-field .input-group-container .add-button {
  margin-top: 0.5rem;
}
.create-form .price-field .prices-table {
  margin-inline: auto;
}
.create-form .price-field .prices-table th {
  padding-inline: 0.3rem;
}
@media (max-width: 480px) {
  .create-form .price-field {
    flex-direction: column;
  }
}